<template>
    <footer class="f-footer">
        <div class="narrow-container">
            <social-media-links class="desktop"/>
            <social-media-mobile-links class="mobile"/>
            <span class="copyright">Copyright © Vitra 2024. All rights reserved.</span>
        </div>
    </footer>
</template>

<script>
import SocialMediaLinks from "@/components/SocialMediaLinks.vue";
import SocialMediaMobileLinks from "@/components/SocialMediaMobileLinks.vue";

    export default {
        components: {
            SocialMediaLinks,
            SocialMediaMobileLinks,
        }
    }
</script>

<style lang="scss" scoped>
.f-footer {
    --f-footer-link-color: #{$theme-color};

    color: #fff;
    .narrow-container {
        display: flex;
        flex-direction: column;
        padding-top: 8px;
        padding-bottom: 16px;
        height: 100%;
        width: 100%;

        > .row {
            height: 100%;
        }
    }

    @include links() {
        color: var(--f-footer-link-color);
        transition: opacity $transition-length ease;
    }

    a:not(.btn):hover {
        text-decoration: none;
        opacity: 0.5;
    }

    .desktop {
        display: none;
        @media (min-width: 620px) {
            display: flex;
        }
    }
    .mobile {
        display: grid;
        @media (min-width: 620px) {
            display: none;
        }
    }
}

  
</style>
